import BasePlugin from '../BasePlugin'

export default class SendFileEDMSAutomatically extends BasePlugin {
  async execute () {
    let me = this
    let roleId = this.context.getParentContext().$store.getters['Authorization/roleId']
    if ([104, 34].includes(roleId)) {
      let card = this.context.getParentContext().getCard()
      let adjustmentRequestId = card.data.id
      let signer = this.context.getModel().podpis
      let authorId = card.data.attr_3034_
      let routeId = card.data.attr_3040_
      let routeName = ''
      if (routeId === 5) {
        routeName = 'инвестпланирование'
      } else {
        routeName = 'корректировка'
      }
      let authorName = null
      try {
        let response = await this.context.$http({
          method: 'get',
          url: `${this.context.$config.api}/accesseditor/users/${authorId}`,
          hideNotification: true
        })
        authorName = response.data.surname + ' ' + response.data.name + ' ' + response.data.midname
      } catch {
        this.context.$msgbox.close()
        this.context.$msgbox({
          type: 'info',
          message: 'Произошла ошибка'
        })
        return
      }
      this.context.$msgbox.close()
      this.context.$confirm(
        `Процедура согласования будет отправлена от имени ${authorName} по маршруту ${routeName}`,
        'Внимание',
        {
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет',
          type: 'warning'
        }
      ).then((confirmResult) => {
        if (confirmResult === 'confirm') {
          me.context.$http({
            method: 'post',
            url: `${me.context.$config.api}/registryservice/plugins/execute/SendEDMSCommand`,
            data: { 'adjustment_request_id': adjustmentRequestId, 'signer_id': signer, 'manual': false }
          }).then((response) => {
            me.context.$msgbox.close()
            me.context.$msgbox({
              type: 'info',
              message: 'Процедура согласования отправлена в СЭД автоматическим способом'
            })
          })
        }
      }).catch((reason) => {
      })
    }
  }
}
